<template>
  <div>
    <v-app-bar color="#002942"
      ><router-link style="text-decoration: none; color: inherit" to="/">
        <v-img
          class="my-4"
          contain
          lazy-src="logo"
          max-height="100"
          max-width="200"
          :src="logo"
        ></v-img
      ></router-link>
      <v-spacer></v-spacer>
      <v-tooltip right v-if="token != null">
        <template v-slot:activator="{ on, attrs }">
          <h3 v-bind="attrs" v-on="on" class="custom-h3">
            Token: {{ token.token }}
          </h3>
        </template>
        <span>Válido hasta: {{ token.fechaVigencia }}</span>
      </v-tooltip>
      <v-spacer></v-spacer>
      <v-menu
        v-if="this.userLogged && Object.keys(userLogged).length > 0"
        left
        bottom
        offset-y
        :close-on-content-click="false"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-chip class="profileChip" color="primary" v-bind="attrs" v-on="on">
            <span>
              {{ userLogged.nombre ? userLogged.nombre : "" }}
            </span>
            <v-avatar class="ml-2">
              <v-icon size="28px">mdi-account-circle</v-icon>
            </v-avatar>
          </v-chip>
        </template>
        <v-card id="profileCard" class="mx-auto" max-width="344">
          <v-card-text class="pb-0">
            <v-row>
              <v-col cols="3" align-self="center">
                <v-avatar class="ml-2 mr-2">
                  <v-icon size="46px">mdi-account-circle</v-icon>
                </v-avatar>
              </v-col>
              <v-col cols="9" align-self="center">
                <h3 class="pl-2 primary--text">
                  {{ userLogged.nombre ? userLogged.nombre : "" }}
                </h3>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="ma-1">
                <p>{{ userLogged.nombre ? userLogged.nombre : "" }}</p>
                <p v-if="userLogged.email && userLogged.email.lenght > 0">
                  {{ userLogged.email }}
                </p>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider class="ml-4 mr-4"></v-divider>
          <v-card-actions>
            <v-row>
              <v-col cols="12" class="actionCol">
                <v-list>
                  <v-list-item
                    link
                    v-if="userLogged.usuarioNombre !== 'gecrossuperusuario'"
                  >
                    <v-list-item-title @click="openChangePassword()"
                      >Cambiar contraseña</v-list-item-title
                    >
                  </v-list-item>
                  <v-list-item link>
                    <v-list-item-title @click="logout"
                      >Cerrar sesión</v-list-item-title
                    >
                  </v-list-item>
                </v-list>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-menu>

      <!-- <div>
        <v-expansion-panels
          v-model="panel"
          dark
          class="custom-style"
          :style="openMenu ? 'margin-bottom: -65px; z-index: 1000;' : ''">
          <v-expansion-panel @click="cambioEstilo()">
            <v-card
              v-if="this.userLogged && Object.keys(userLogged).length > 0"
              color="primary"
              dark
              elevation="2"
              class="mx-0 my-0">
              <v-expansion-panel-header>
                {{ text
                }}<v-icon big class="ml-6"
                  >mdi-account</v-icon
                ></v-expansion-panel-header
              >
              <v-expansion-panel-content>
                <div class="ml-3">
                  <button @click="cambioPassword">Cambiar contraseña</button>
                </div>
              </v-expansion-panel-content>

              <v-expansion-panel-content>
                <div class="ml-3">
                  <button @click="logout">Cerrar sesión</button>
                </div>
              </v-expansion-panel-content>
            </v-card>
          </v-expansion-panel>
        </v-expansion-panels>
      </div> -->

      <v-dialog
        v-if="openModalChangePassword"
        v-model="openModalChangePassword"
        max-width="40%"
        @keydown.esc="closeAndReload"
        persistent
      >
        <ChangePassword
          @closeAndReload="closeAndReload"
          :userLogged="userLogged"
        ></ChangePassword>
      </v-dialog>
    </v-app-bar>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { mapActions } from "vuex";
import ChangePassword from "../views/ChangePassword.vue";
export default {
  name: "NavBar",
  data: () => ({
    logo: require("../assets/MacenaLogo.png"),
    panel: true,
    token: null,
    //text: "",
    openMenu: false,
    openModalChangePassword: false,
  }),
  components: {
    ChangePassword,
  },
  computed: {
    ...mapGetters({
      userLogged: "user/user",
    }),
    text() {
      if (this.userLogged) {
        return this.userLogged.nombre;
      } else {
        return "";
      }
    },
  },
  async created() {
    await this.$store.dispatch("user/setUser");
    this.setTwoFactorAuthenticationToken();
  },
  methods: {
    ...mapActions({
      getTwoFactorAuthenticationToken: "user/getTwoFactorAuthenticationToken",
    }),
    async setTwoFactorAuthenticationToken() {
      if (localStorage.token) {
        const response = await this.getTwoFactorAuthenticationToken();
        this.token = response;
      }
    },
    closeAndReload() {
      this.openModalChangePassword = false;
    },
    // openChangePassword() {
    //   this.$router.push({ name: "ChangePassword" });
    // },
    openChangePassword() {
      this.openModalChangePassword = true;
      //  this.$bus.$emit("openModal", this.openModalChangePassword);
    },
    logout() {
      localStorage.removeItem("token");
      this.panel = false;
      this.$router.push({ name: "Home" });
      window.location.reload();
    },
  },
};
</script>
<style scoped>
.custom-h3 {
  cursor: default !important;
  color: lightblue;
}
</style>
