import messages from "./messages";
import icons from "./icons";
import buttons from "./buttons";
import titles from "./titles";
import webSiteOptions from "./modules/webSiteOptions";
import modules from "./modules/index";

export default {
  messages,
  icons,
  buttons,
  titles,
  webSiteOptions,
  modules,
};
