const state = {
  user: {},
  modulos: [],
  activeMenu: "",
  activeMenuLevel: [],
  favoritos: [],
  linksAsideBar: [],
  appLoading: false,
  login: null,
  alert: null,
  grupoUsuarios: [],
  filtersClientes: null,
};

export default state;
