import axios from "axios";
import router from "../router";
import getAPIUrl from "../utils/helpers/getAPIUrl";
import store from "../store";
import enums from "../utils/enums/index.js";

axios.interceptors.request.use(
  async (config) => {
    config.baseURL = await getAPIUrl();
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    config.headers.Accept = "application/json";
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response.status === 401) {
      localStorage.removeItem("token");
      router.push("/");
      return;
    }
    if (error.response.status === 403) {
      router.push("/");
      alert(enums.messages.ACCESS_DENIED);
    } else if (error.response.status >= 400 && error.response.status < 500) {
      if (error.config.url === "/connect/token") {
        store.commit(
          "user/setAlertMessage",
          {
            type: "warning",
            message: error.response.data.error_description,
          },
          { root: true }
        );
        return;
      }
      if (
        Array.isArray(error.response.data.errors) &&
        error.response.data !== null &&
        error.response.data !== "undefined" &&
        error.response.data !== ""
      ) {
        store.commit(
          "user/setAlertMessage",
          {
            type: "warning",
            message: Array.isArray(error.response.data.errors)
              ? error.response.data.errors[0]
              : error.response.data,
          },
          { root: true }
        );
      } else {
        store.commit(
          "user/setAlertMessage",
          {
            type: "warning",
            message: enums.messages.SYSTEM_ERROR,
          },
          { root: true }
        );
      }
    } else if (error.response.status >= 500) {
      store.commit(
        "user/setAlertMessage",
        {
          type: "error",
          message: enums.messages.SYSTEM_ERROR,
        },
        { root: true }
      );
    }
    return Promise.reject(error);
  }
);

export default axios;
