import axios from "../../../axios/axios-api";

const actions = {
  async getUsuariosInfo(context, payload) {
    const response = await axios.get(`api/Usuarios/UsersInfo/${payload}`);
    return response.data.data;
  },
  async getGruposUsuario() {
    const response = await axios.get(`api/Usuarios/UsersGroups`);
    return response.data.data;
  },
  async saveGrupoUsuario(context, payload) {
    const response = await axios.post("api/Usuarios/SaveGrupo", payload);
    return response;
  },
  async deleteGrupoUsuario(context, payload) {
    const response = await axios.delete(`api/Usuarios/DeleteGrupo/${payload}`);
    return response;
  },
  async getUsuariosYPerm(context, payload) {
    const response = await axios.get(`api/Usuarios/GetUsuariosYPer/${payload}`);
    return response.data.data;
  },
  async deleteUsuario(context, payload) {
    const response = await axios.delete(`api/Usuarios/DeleteUser/${payload}`);
    return response;
  },
  async saveUsuario(context, payload) {
    const response = await axios.post("api/Usuarios/SaveUser", payload);
    return response;
  },
  async restablecerPass(context, payload) {
    const response = await axios.post(
      "api/Usuarios/RestablecerPassword",
      payload
    );
    return response;
  },
  async permisosByGroup(context, payload) {
    const response = await axios.post(
      `api/WebSiteOptions/OptionsByGroupId/${payload}`
    );
    return response.data.data;
  },
  async SavePermissions(context, payload) {
    const response = await axios.post(
      "api/WebSiteOptions/SavePermissions",
      payload
    );
    return response;
  },
  async SaveFilters(context, payload) {
    const response = await axios.post(
      "api/WebSiteOptions/filters/save",
      payload
    );
    return response;
  },
  async getOptions() {
    const response = await axios.get(`api/WebSiteOptions/Options`);
    return response.data;
  },
  async massPasswordChange(context, payload) {
    const response = await axios.post(
      `api/usuarios/MassPasswordChange/${payload}`
    );
    return response;
  },
  async newPassword(context, payload) {
    const response = await axios.post(
      `api/usuarios/RestablecerPassword/${payload.id}/${payload.newPassword}`
    );
    return response;
  },
};

export default actions;
