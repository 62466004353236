const rules = {
  required: [(v) => !!v || "Campo requerido"],
  validDate: [
    // formato DD/MM/YYYY
    (v) =>
      /^(?:(?:31(\/)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)\d{2})$/.test(
        v
      ) || "Formato de fecha no válido",
  ],
  minMaxNumber: (value, min, max) => {
    return (value) =>
      !value ||
      (Number.isInteger(Number(value)) && value >= min && value <= max) ||
      `Número entre ${min} y ${max}`;
  },
  validEmail: (v) =>
    (!v || /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(v)) ||
    "Formato de email no válido",
  validAnio: [
    // formato DD/MM/YYYY
    (v) => /^$|^(\d{4}\s*,\s*)*\d{4}$/.test(v) || "Formato de año no válido",
  ],
  validDateRange: (fechaDesde, fechaHasta) => {
    return fechaDesde <= fechaHasta || "Rango no válido";
  },
  requiredTrim: (value) => {
    return (
      value == null ||
      !value.startsWith(" ") ||
      "No se permite iniciar con espacio"
    );
  },

  maxLength: (value, limit) => {
    return (value) =>
      !value || `${value}`.length <= limit || `Máximo ${limit} caracteres`;
  },
  password: [
    (v) =>
      (!!v && /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/.test(v)) ||
      "Min. 8 caracteres con al menos una mayúscula y un número",
  ],
  samePassword: (firstPassword, secondPassword) => {
    return firstPassword == secondPassword || "Las contraseñas no coinciden";
  },
  noSpecialCharacters: [
    (v) =>
      !/[,@{}[\]]/.test(v) ||
      "No se permiten los caracteres '@', ',', '/', '{', '}', '[' y ']'",
  ],
  positiveNumberHigherThanCero: [
    (v) => (Number.isInteger(Number(v)) && v > 0) || "Formato no válido",
  ],
  validTime: (value) => {
    if (!value) return "Campo requerido";
    // formato HH/mm
    const horasMinutos = value?.split(":");
    return (
      (horasMinutos[0] < 24 &&
        horasMinutos[1] < 60 &&
        horasMinutos[1].length == 2) ||
      "Formato de hora no válido"
    );
  }
};
export default rules;
