<template>
  <div>
    <v-footer color="#011d2e" dark>
      <div class="mx-16">© {{ new Date().getFullYear() }} - Macena</div>
    </v-footer>
  </div>
</template>

<script>
export default {
  name: "FootBar",
};
</script>
