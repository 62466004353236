const messages = Object.freeze({
  ADD_SUCCESS: "Registro agregado correctamente",
  UPDATE_SUCCESS: "Registro actualizado correctamente",
  DELETE_SUCCESS: "Registro eliminado correctamente",
  DELETE_TEXT: "¿Eliminar este registro?",
  ADD_ERROR: "Error en la creacion",
  UPDATE_ERROR: "Error al actualizar",
  DELETE_ERROR: "Error al eliminar",
  SAVE_CONFIRM: "¿Desea guardar los datos?",
  RETURN_CONFIRM: "¿Desea volver atrás?",
  RETURN_HOME_CONFIRM: "¿Desea volver a la página principal?",
  SYSTEM_ERROR: "Se produjo un error. Intente nuevamente.",
  ACCESS_DENIED: "Acceso denegado.",
});

export default messages;
