const mutations = {
  setUser(state, val) {
    state.user = val;
  },
  setModulos(state, modulos) {
    state.modulos = modulos;
  },
  setFavoritos(state, favoritos) {
    state.favoritos = favoritos;
  },
  seLinksAsideBar(state, linksAsideBar) {
    state.linksAsideBar = linksAsideBar;
  },
  setAppLoading(state, val) {
    state.appLoading = val;
  },
  setActiveMenu(state, val) {
    state.activeMenu = val;
  },
  setLogin(state, val) {
    state.login = val;
  },
  setActiveMenuLevel(state, { val, add }) {
    if (add) {
      state.activeMenuLevel.push(val);
    } else {
      state.activeMenuLevel.splice(val, state.activeMenuLevel.length);
    }
  },
  setAlertMessage(state, alert) {
    state.alert = alert;
  },
  setGrupoUsuarios(state, grupoUsuarios) {
    state.grupoUsuarios = grupoUsuarios;
  },
};

export default mutations;
