const webSiteOptions = Object.freeze({
  LICENCIASYCLIENTES: "09C60F57-F4DD-48AD-BD2F-144E1FAD24DE",
  ENVIODATOSCLIENTES: "F738F2D1-16EA-409D-948E-1AFE201CF891",
  CLIENTES: "B2AAC48F-9A43-4785-85BE-ED604C6014BB",
  USUARIOSYPERMISOS: "EAF9F69E-5160-4876-A4C4-81E246293B71",
  INCIDENTES: "ABF5A720-50B8-40F9-8670-08CEB64B15D6",
  DATOS_ACCESO: "9E5E786A-B24D-49A1-9E83-7AD70702E8BB",
  CONEXIONES_RDP: "CF624F00-BE93-471E-88BC-BBEC1BDD51DD",
  FECHA_CORTE: "07F8BE80-A583-4466-9BEF-3B64D8AFE37A",
  FERIADO: "AC68ADA0-E1E8-4164-A198-A4222BB34AC8",
  VER_TODO_CLIENTES: "DB206F0F-7D8C-428B-A5BB-FD4F0AEF8D82",
  DOBLE_FACTOR_AUTENTICACION: "440284CE-CDC3-4C21-AA63-5CD15E3639A9",
});

export default webSiteOptions;
