import axios from "../../../axios/axios-api";
import getAPIUrl from "../../../utils/helpers/getAPIUrl.js";

const actions = {
  async setLogin(context, userData) {
    const urlencoded = new URLSearchParams();
    urlencoded.append("userName", userData.user);
    urlencoded.append("password", userData.password);
    urlencoded.append("grant_type", userData.grand_type);
    urlencoded.append("client_id", userData.client_id);
    try {
      const result = await axios.post("/connect/token", urlencoded);
      if (result.data.error) {
        setTimeout(() => {
          context.commit("setLogin", null);
        }, 3000);
      } else {
        localStorage.setItem("token", result.data.access_token);
      }
      context.commit("setLogin", result.data);
    } catch (error) {
      if (error?.response?.status === 400) {
        context.commit("setLogin", { validationError: true });
      } else {
        context.commit("setLogin", { error: true });
      }
      setTimeout(() => {
        context.commit("setLogin", null);
      }, 5000);
    }
  },
  async setUser(context) {
    context.dispatch("changeAppLoading", {
      status: true,
      text: "",
      opaque: true,
    });
    await axios.get("/api/usuarios/profile").then((res) => {
      res.status == 204
        ? localStorage.removeItem("token")
        : context.commit("setUser", res.data);
      context.dispatch("changeAppLoading", {
        status: false,
        text: "",
        opaque: false,
      });
    });
  },
  changeAppLoading({ commit }, val) {
    commit("setAppLoading", val);
  },
  setAlert(context, alert) {
    context.commit("setAlertMessage", alert);
  },
  async updateFrequencyByOptionCode(context, code) {
    //updetear como visitado cdo se ingresa por url y no por navegacion
    try {
      const option = await axios.get(
        `api/webSiteOptions/optionsByCode?code=${code}`
      );
      // si hay acciones en localStorage, se pisan con las permitidas para esa opcion y usuario
      const actions = localStorage.getItem("allowedActions");
      if (actions) {
        localStorage.removeItem("allowedActions");
      }
      localStorage.setItem(
        "allowedActions",
        JSON.stringify(option.data?.children)
      );
    } catch (error) {
      console.error("Error al hacer la solicitud a la API:", error);
      throw error;
    }
  },
  async getTwoFactorAuthenticationToken() {
    const response = await axios.get(
      "api/taxonomy/getTwoFactorAuthenticationToken"
    );
    return response.data.data;
  },
  async userHasToChangePassword() {
    const response = await axios.get("api/usuarios/userHasToChangePassword");
    return response.data.data;
  },
  async changeUserPassword(context, payload) {
    const response = await axios.post(`api/usuarios/changePassword/${payload}`);
    return response;
  },
  async changePass(context, payload) {
    const response = await axios.post("api/usuarios/ChangePass", payload);
    return response;
  },
  async getConfigGeneralDobleFactor() {
    const h = await getAPIUrl();
    const response = await axios.get(
      `${h}/api/DobleFactorAutenticacion/GetConfiguracionGeneral`
    );
    return response.data.data;
  },
  async saveConfigGeneralDobleFactor(context, payload) {
    const h = await getAPIUrl();
    const response = await axios.post(
      `${h}/api/DobleFactorAutenticacion/SaveConfiguracionGeneral`,
      payload
    );
    return response;
  },
  async getConfigXUsuarioDobleFactor() {
    const h = await getAPIUrl();
    const response = await axios.get(
      `${h}/api/DobleFactorAutenticacion/GetConfiguracionXUsuario`
    );
    return response.data.data;
  },
  async saveConfiguracionXUsuario(context, payload) {
    const h = await getAPIUrl();
    const response = await axios.post(
      `${h}/api/DobleFactorAutenticacion/SaveConfiguracionXUsuario`,
      payload
    );
    return response;
  },
  async removeUserQRSecretKey(context, payload) {
    const response = await axios.get(
      `api/DobleFactorAutenticacion/remove-user-qr/${payload}`
    );
    return response.data.data;
  },
  async userRequireTwoFactorAuthentication(context, payload) {
    const h = await getAPIUrl();
    const response = await axios.get(
      `${h}/api/DobleFactorAutenticacion/user-require-2fa/${payload}`
    );
    return response.data.data;
  },
  async validateUserAndReturnId(context, payload) {
    const response = await axios.get(
      `api/dobleFactorAutenticacion/return-userId/${payload.username}/${payload.password}`
    );
    return response.data.data.id;
  },
  async generateUserQR(context, payload) {
    const response = await axios.get(
      `api/dobleFactorAutenticacion/generate-qr/${payload}`,
      { responseType: "arraybuffer" }
    );
    const blob = new Blob([response.data], { type: "image/png" });
    const imageUrl = URL.createObjectURL(blob);
    return imageUrl;
  },
  async verifyTwoFactorAuthentication(context, payload) {
    const response = await axios.get(
      `api/dobleFactorAutenticacion/verify-2fa/${payload.userId}/${payload.code}/${payload.userName}`
    );
    return response.data.data;
  },
};

export default actions;
