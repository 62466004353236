import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import VueExcelXlsx from "vue-excel-xlsx";
import VueTheMask from "vue-the-mask";

// deteccion de inactividad
import IdleVue from "idle-vue";

const eventsHub = new Vue();
Vue.use(IdleVue, {
  eventEmitter: eventsHub,
  store,
  idleTime: 10800000, // 3 horas
  startAtIdle: false,
});

Vue.config.productionTip = false;
Vue.use(VueExcelXlsx);
Vue.use(VueTheMask);

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
