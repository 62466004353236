const getters = {
  user: (state) => state.user,
  modulos: (state) => state.modulos,
  favoritos: (state) => state.favoritos,
  linksAsideBar: (state) => state.linksAsideBar,
  appLoading: (state) => state.appLoading,
  activeMenu: (state) => state.activeMenu,
  activeMenuLevel: (state) => state.activeMenuLevel,
  login: (state) => state.login,
  alert: (state) => state.alert,
  grupoUsuarios: (state) => state.grupoUsuarios,
};

export default getters;
