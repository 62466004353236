const icons = Object.freeze({
  DEFAULT_ICON: "mdi-cancel",
  CLEAR_FILTERS: "mdi-filter-remove-outline",
  CLOSE_FILTERS: "mdi-tune",
  SEARCH_ICON: "mdi-magnify",
  EXCEL_ICON: "mdi-microsoft-excel",
  CALENDAR_ICON: "mdi-calendar",
  CHEVRON_DOWN: "mdi-chevron-down",
  CHEVRON_UP: "mdi-chevron-up",
  EDIT: "mdi-pencil",
  CLOSE_BOX: "mdi-close-box",
  CHECKBOX_BLANK_OUTLINE: "mdi-checkbox-blank-outline",
  DELETE: "mdi-trash-can-outline",
  USER: "mdi-account",
  COPY: "mdi-content-copy",
  USER_CORBATA: "mdi-account-tie",
  SETTINGS: "mdi-cog",
  SNB_SUCCESS: "mdi-check-circle",
  SNB_ERROR: "mdi-alert-octagon-outline",
  SNB_INFO: "mdi-information-outline",
  SNB_WARNING: "mdi-alert-outline",
  ACCES_DATA: "mdi-database-plus",
  CHECK_OUTLINE: "mdi-check-outline",
  CROSS_OUTLINE: "mdi-close-outline",
  ICON_CONNECTION: "mdi-lan-connect",
  ICON_CONECTAR: "mdi-lock-pattern",
  ICON_ACTIVE: "mdi-circle",
  SEE: "mdi-eye",
  PADLOCK: "mdi-lock-reset",
  CIRCLE_NONE: "mdi-minus-circle",
  HISTORIC: "mdi-history",
  QRCODE_REMOVE: "mdi-qrcode-remove",
  SETTING:"mdi-cog-sync",
  SETTING2:"mdi-cogs",
  CONTACTS: "mdi-human-greeting-proximity"
});

export default icons;
