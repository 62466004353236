<template>
  <div>
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-row justify="center">
        <v-dialog v-model="modalOpen" persistent max-width="450px">
          <v-card>
            <v-card-title>
              <span class="pl-1 primary--text">Cambiar contraseña</span>
            </v-card-title>
            <v-card-text class="pb-0">
              <v-row>
                <v-col cols="12" class="pt-3 pb-0">
                  <v-text-field
                    tabindex="2"
                    :append-icon="currentPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="() => (textType = !textType)"
                    v-model="currentPassword"
                    :type="textType ? 'password' : 'text'"
                    label="Contraseña actual"
                    @focus="inputActive = 'currentPassword'"
                    @blur="inputActive = ''"
                    required
                    outlined
                    dense
                    :rules="rules.required"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="pt-2 pb-0">
                  <v-text-field
                    tabindex="2"
                    :append-icon="newPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="() => (textTypeNewPass = !textTypeNewPass)"
                    :type="textTypeNewPass ? 'password' : 'text'"
                    @focus="inputActive = 'newPassword'"
                    @blur="inputActive = ''"
                    label="Nueva contraseña"
                    hint="Escriba una contraseña min. 8 caracteres con al menos una mayúscula y un número"
                    required
                    outlined
                    dense
                    v-model="newPassword"
                    :rules="rules.password"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="pt-2 pb-0">
                  <v-text-field
                    tabindex="2"
                    :append-icon="confirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="
                      () => (textTypeConfirmPass = !textTypeConfirmPass)
                    "
                    :type="textTypeConfirmPass ? 'password' : 'text'"
                    @focus="inputActive = 'newPassword'"
                    @blur="inputActive = ''"
                    label="Confirmar contraseña"
                    hint="Repita su nueva contraseña"
                    required
                    outlined
                    dense
                    v-model="confirmPassword"
                    :rules="confirmPassRules"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn outlined @click="closeModal"> Cerrar </v-btn>
              <v-btn
                type="submit"
                color="primary"
                @click="saveNewPassword"
                :disabled="!valid"
              >
                Guardar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </v-form>
  </div>
</template>
<script>
import { mapActions } from "vuex";
//import axios from "@/axios/axios-api";
import rules from "@/utils/helpers/rules";
export default {
  name: "ChangePassword",
  data() {
    return {
      valid: false,
      modalOpen: false,
      currentPassword: "",
      rules: rules,
      textType: String,
      textTypeNewPass: String,
      textTypeConfirmPass: String,
      newPassword: "",
      confirmPassword: "",
      currentPassRules: [(v) => !!v || "Ingrese su contraseña"],
      newPassRules: [(v) => !!v || "Ingrese nueva contraseña"],
      confirmPassRules: [
        (v) => !!v || "Confirme nueva contraseña",
        (v) => v === this.newPassword || "Las contraseñas no coinciden",
      ],
    };
  },
  props: {
    userLogged: { type: Object, default: null },
  },
  created() {
    // this.$bus.$on("openModal", (data) => {
    this.modalOpen = true;
    // });
  },
  methods: {
    ...mapActions({
      setAlert: "user/setAlert",
      changePass: "user/changePass",
      user: "user/user",
      loginUser: "user/login",
    }),
    closeModal() {
      this.$emit("closeAndReload");
      this.$refs.form.reset();
      this.modalOpen = false;
    },
    async saveNewPassword() {
      this.isLoading = true;
      // Seteo el token para que pueda hacer la llamada al endpoint
      localStorage.setItem("token", this.token);
      try {
        const data = {
          currentPass: this.currentPassword,
          newPass: this.newPassword,
          usuario: this.userLogged.id,
        };

        const response = await this.changePass(data);
        if (response.status === 200) {
          this.setAlert({
            type: "success",
            message:
              "Contraseña actualizada con éxito. Vuelva a loguearse por favor.",
          });

          window.location.reload();
          this.$router.push({ name: "Home" });
        }
      } catch (error) {
        this.isLoading = false;
      }
    },
  },
};
</script>
