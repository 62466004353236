<template>
  <v-app>
    <nav-bar />
    <v-main>
      <v-dialog v-model="isIdle" max-width="35%" persistent>
        <IdleDialog />
      </v-dialog>
      <router-view />
      <snack-bar />
    </v-main>

    <foot-bar />
  </v-app>
</template>

<script>
import NavBar from "./components/NavBar.vue";
import FootBar from "./components/FootBar.vue";
import SnackBar from "@/components/layout/SnackBar";
import IdleDialog from "@/components/shared/IdleDialog";

export default {
  name: "App",

  components: {
    NavBar,
    FootBar,
    SnackBar,
    IdleDialog,
  },

  data() {
    return {};
  },
  computed: {
    isIdle() {
      if (localStorage.getItem("token") == null || localStorage.getItem("token") == "") return false;
      else return this.$store.state.idleVue.isIdle;
    },
  },

  created() {},

  methods: {},
};
</script>
