<template>
  <v-card>
    <v-card-title class="headline primary--text">{{ title }}</v-card-title>
    <v-card-text class="text-left"
      ><h6>{{ textIdle }}</h6></v-card-text
    >
  </v-card>
</template>
<script>
import enums from "@/utils/enums/index.js";

export default {
  name: "IdleDialog",
  data() {
    return {
      title: enums.titles.IDLE_DIALOG,
      time: 10000,
    };
  },
  computed: {
    textIdle() {
      return `Se cerrará la sesión en ${this.second}`;
    },
    second() {
      return this.time / 1000;
    },
  },
  created() {
    let timerId = setInterval(() => {
      this.time -= 1000;
      if (!this.$store.state.idleVue.isIdle) {
        clearInterval(timerId);
      }
      if (this.time < 1) {
        clearInterval(timerId);
        this.closeSession();
      }
    }, 1000);
  },

  methods: {
    closeSession() {
      this.$emit("update:openDialog", false);
      localStorage.removeItem("token");
      this.$router.push({ name: "Home" });
      window.location.reload();
    },
  },
};
</script>
