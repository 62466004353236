import Vue from "vue";
import Vuex from "vuex";
import clientesData from "./modules/clientes";
import usuarios from "./modules/usuarios";
import user from "./modules/user";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    user,
    clientesData,
    usuarios,
  },
});
